/* Sans Serif */
$primaryFont: 'SourceSansPro';

/* Serif */
$secondaryFont: 'SourceSerifPro';

/* Sans Serif */
@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Light.ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Italic.ttf');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/SourceSansPro/SourceSansPro-SemiBold.ttf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/SourceSansPro/SourceSansPro-SemiBoldItalic.ttf');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Bold.ttf');
  font-style: medium;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/SourceSansPro/SourceSansPro-BoldItalic.ttf');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSansPro';
  src: url('/fonts/SourceSansPro/SourceSansPro-Black.ttf');
  font-style: medium;
  font-weight: 800;
  font-display: swap;
}

/* Serif */
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-Light.ttf');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-LightIt.ttf');
  font-style: italic;
  font-weight: 300;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-Regular.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-It.ttf');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-SemiBold.ttf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-SemiBoldIt.ttf');
  font-style: italic;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-Bold.ttf');
  font-style: medium;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-BoldIt.ttf');
  font-style: italic;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'SourceSerifPro';
  src: url('/fonts/SourceSerifPro/SourceSerifPro-Black.ttf');
  font-style: medium;
  font-weight: 800;
  font-display: swap;
}

/* Icons */
@font-face {
  font-family: 'AntIcon';
  src: url('/fonts/Icons/AntDesign.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'FontistoIcon';
  src: url('/fonts/Icons/Fontisto.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'SimpleLineIcon';
  src: url('/fonts/Icons/SimpleLineIcons.ttf') format('truetype');
  font-style: normal;
  font-weight: 400;
}
