@import '../../colors.scss';
@import '../../fonts.scss';
@import '../../structure.scss';

$smallGutter: 16px;
$gutter: 24px;
$largeGutter: 32px;

article.positionUpdate {
  p {
    line-height: 1.25;
    letter-spacing: -0.1px;
  }

  h1 {
    background-color: $orange;
    padding: 16px;
    color: white;
    font-weight: 900;
    font-size: 22px;
    text-transform: uppercase;
    margin-bottom: 0;

    @include smallScreen {
      font-size: 20px;
      margin-right: -20px;
      margin-left: -20px;
      margin-top: -24px;
    }
  }

  .quote {
    background-color: $darkDefaultGray;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: $smallGutter;
    padding: 12px 16px;

    @include smallScreen {
      margin-right: -20px;
      margin-left: -20px;
      text-align: center;
    }

    .report {
      text-transform: capitalize;
      font-family: $secondaryFont;
      font-weight: 600;
    }
  }
}
