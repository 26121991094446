@import "../../structure.scss";

.publication.post {
  .bullVsBear {
    display: flex;
    margin-top: 24px;

    @include smallScreen {
      display: block;
    }
  }

  .bull {
    background-color: rgba(101, 175, 86, 0.2);
  }

  .bear {
    background-color: rgba(211, 63, 43, 0.2);
  }

  .bull,
  .bear {
    padding: 24px;

    h2 {
      font-size: 18px;
    }

    p {
      line-height: 1.5;
    }

    li {
      line-height: 1.45;
    }
  }
}
