@import './fonts.scss';
@import './structure.scss';
@import './colors.scss';
@import './tags';

.rpv-default-layout__sidebar--ltr {
  @include mediumScreen {
    display: none;
  }
}

.rpv-default-layout__toolbar {
  @include mediumScreen {
    position: fixed;
    top: 0;
    z-index: 9999;
    width: 100%;
  }
}

.rpv-default-layout__container {
  border: none !important;
}
// https://github.com/react-pdf-viewer/react-pdf-viewer/issues/810
// div[data-testid="core__inner-pages"] {
//   overflow-x: hidden !important;
//   overflow-y: auto !important;
// }

.show-scrollbar::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.show-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.gutter {
  padding: var(--gutter);
}

.pr {
  padding-right: var(--gutter);

  @include smallScreen {
    padding-right: 0;
  }
}

.pl {
  padding-left: var(--gutter);

  @include smallScreen {
    padding-left: 0;
  }
}

.pt {
  padding-top: var(--gutter);
}

.pb {
  padding-bottom: var(--gutter);
}

.py {
  padding-top: var(--gutter);
  padding-bottom: var(--gutter);
}

.between {
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 22px;
  align-items: center;
  padding: 28px var(--gutter) 20px;
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  // background-color: white;
  background-color: $lightDefaultGray;

  @include smallScreen {
    border: none;
    box-shadow: inset 2px 0px 2px 0px rgb(200 200 200 / 50%);
  }

  h1 {
    margin-bottom: 0;
    font-size: 18px;
  }
}

.dash-view {
  background-color: $lightDefaultGray;
  min-height: 100vh;

  .header {
    background-color: $lightDefaultGray;
  }

  .content {
    padding: var(--gutter);

    @include mediumScreen {
      padding: 0 16px 64px;
    }

    @include smallScreen {
      padding: 0 16px 54px;
    }
  }

  &.ss-page {
    .content {
      padding-top: 8px;

      @include smallScreen {
        padding-top: 0;
      }
    }
  }
}

@include smallScreen {
  .header {
    position: static;
    padding: 16px 0 12px;
    margin-bottom: 6px;
    background-color: transparent;
    justify-content: center;
  }

  .dash-view h2 {
    display: none;
  }

  .dash-view .header {
    border-bottom: none;
    box-shadow: none;
  }
}

// Tables
.table-container {
  overflow-y: auto;
  // padding: 0 var(--gutter);
  margin-bottom: var(--gutter);
  background-color: white;
  border-bottom: 0.25px solid $defaultGray;

  // border-top: 1px solid $defaultGray;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  @include mediumScreen {
    border-bottom: 1px solid $defaultGray;
  }

  @include smallScreen {
    margin-right: 0;
    margin-left: 0;
  }
}

.excerpt {
  p {
    margin-bottom: 0;
    color: $darkGrey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
  }
}

.speaker {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 900;
}

.warren {
  color: #bc5d2e;
}

.fernando {
  color: #2e6abc;
}
