@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
    font-size: 100%;
  }

  @media screen and (min-width: 1920px) {
    html {
      font-size: 120%;
    }
  }
}

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }

  .pb-safe-half {
    padding-bottom: calc(env(safe-area-inset-bottom) / 2);
  }

  .hover-teal {
    @apply hover:bg-teal-300/20 transition-colors duration-200;
  }
}

@layer components {
  .btn {
    @apply h-12 rounded-full px-5 font-semibold flex items-center justify-center;
  }
  .btn-primary {
    @apply text-white bg-teal-800 border border-solid border-teal-800 tracking-wide shadow-[0_2px_2px_rgb(0_0_0_/_30%)] disabled:bg-slate-450 disabled:border-slate-450;
  }
  .btn-secondary {
    @apply text-teal-800 bg-white border border-solid border-teal-800 shadow-[0_2px_2px_rgb(0_0_0_/_30%)] disabled:border-gray-300 disabled:text-gray-400;
  }
  .btn-lg {
    @apply h-16 px-8 text-xl;
  }
  .btn-sm {
    @apply h-10 px-4;
  }
  .btn-xs {
    @apply h-8 px-3 text-sm;
  }
  .btn-xxs {
    @apply h-6 px-2 text-xs;
  }
}

.retro p {
  margin-bottom: 20px;
}

.rpv-core__minimal-button {
  @apply text-sm font-medium;
  height: auto;
  padding: 4px 8px !important;
}

.rpv-core__minimal-button:hover {
  @apply hover-teal;
}

.rpv-zoom__popover-target-arrow {
  border-width: 0.5rem 0.3rem 0 !important;
  border-radius: 2px;
}

.rpv-core__menu-item-label {
  @apply text-sm font-medium;
}

.rpv-page-navigation__current-page-input {
  @apply text-sm;
  width: 2rem !important;
  @apply font-medium;
}

.rpv-page-navigation__current-page-input .rpv-core__textbox {
  @apply text-sm;
  padding: 0 !important;
  text-align: center;
  height: auto !important;
}
