@import './structure.scss';
@import './colors.scss';
// div[data-testid="core__inner-pages"] {
//   overflow-x: hidden !important;
//   overflow-y: auto !important;
// }

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'SourceSansPro', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $tealBlack;

  @include mediumScreen {
    height: auto;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h3 {
  margin-bottom: 1rem;
}

h4,
h5,
h6 {
  margin-bottom: 0.5rem;
}

li {
  list-style: none;
}

strong {
  font-weight: 600;
}

blockquote {
  line-height: 1.5;
  font-style: italic;
  margin-bottom: 1rem;
  text-align: justify;
}

input[type='range'] {
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  height: 0.5rem;

  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 999px;
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']::-webkit-slider-runnable-track {
  background: #6b6a6a;
  height: 0.5rem;
  border-radius: 999px;
}

/******** Firefox ********/
input[type='range']::-moz-range-track {
  background: #6b6a6a;
  height: 0.5rem;
  border-radius: 999px;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;

  /* margin-top = (track height in pixels / 2) - (thumb height in pixels /2) */
  margin-top: 0; /* Centers thumb on the track */
  background-color: #5eead4;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 999px;
  transition: 100ms ease-in;

  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #185d62;
}

/***** Thumb Styles *****/
/***** Firefox *****/
input[type='range']::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  background-color: #5eead4;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 999px;
  transition: 100ms ease-in;

  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #185d62;
}

/***** Focus Styles *****/
/* Removes default focus */
input[type='range']:hover {
  outline: none;
}

/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type='range']:hover::-webkit-slider-thumb {
  // background-color: #40effc;
}

/******** Firefox ********/
input[type='range']:hover::-moz-range-thumb {
  // background-color: #40effc;
}
