@import '../../colors.scss';
@import '../../fonts.scss';
@import '../../structure.scss';

$smallGutter: 16px;
$gutter: 24px;
$largeGutter: 32px;

article.modelUpdate {
  p,
  blockquote {
    line-height: 1.25;
    letter-spacing: -0.1px;
  }

  h1 {
    background-color: $tealLightest;
    padding: 16px 8px;
    color: $teal;
    font-weight: 900;
    font-size: 24px;
    text-transform: uppercase;
    margin-bottom: $smallGutter;

    @include smallScreen {
      font-size: 20px;
      margin-right: -20px;
      margin-left: -20px;
      margin-top: -24px;
    }
  }

  h3 {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 18px;
  }

  .subHeader {
    background-color: $teal;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include smallScreen {
      padding: 10px 0;
    }

    .titleContainer {
      display: flex;
      align-items: center;
    }

    h2 {
      font-size: 24px;
      font-weight: 900;
      color: white;
      margin-left: $gutter;
      margin-bottom: 0;
      text-transform: uppercase;

      @include smallScreen {
        font-size: 18px;
        margin-left: 18px;
      }
    }

    $horizontalPadding: 40px;
    .tag {
      background-color: $tealLightest;
      padding: 4px $horizontalPadding;
      text-transform: uppercase;
      font-weight: 800;
      margin-bottom: 6px;
      font-size: 14px;

      @include smallScreen {
        padding: 4px 10px;
        font-size: 13px;
      }
    }

    .mark {
      width: 75px;

      @include smallScreen {
        display: none;
      }
    }

    .date {
      color: white;
      text-transform: uppercase;
      margin-bottom: 0;
      font-size: 14px;
      margin-right: $horizontalPadding;
      text-align: right;
      font-weight: 600;

      @include smallScreen {
        margin-right: 10px;
        font-size: 13px;
      }
    }
  }

  .topSection {
    display: flex;
    justify-content: space-between;
    margin-top: $gutter;
    margin-bottom: $smallGutter;

    &.intro {
      margin-top: 0;
    }

    @include smallScreen {
      margin-top: $smallGutter;
      display: block;
    }

    .left {
      flex-basis: 65%;
      min-width: 65%;
      margin-right: $gutter;

      @include smallScreen {
        margin-right: 0;
        margin-bottom: $smallGutter;
      }
    }

    .right {
      @include smallScreen {
        display: none;
      }
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .twoCol {
    margin-top: $gutter;
    margin-bottom: $smallGutter;
  }

  .cotw {
    display: flex;
    margin-top: $gutter;
    margin-bottom: $gutter;

    @include smallScreen {
      display: block;
    }

    p {
      &:last-child {
        margin-bottom: 0;
      }
    }

    .left {
      background-color: $tealLightest;
      padding-right: $gutter;
      padding-left: $gutter;
      margin-right: $gutter;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      @include smallScreen {
        display: none;
      }
    }

    .right {
      flex-basis: 70%;
      min-width: 70%;
    }

    .report {
      font-family: $secondaryFont;
      font-weight: 500;
      line-height: 1.5;
    }

    .mobileApp {
      display: flex;
      align-items: center;
      justify-content: center;

      .phone {
        max-width: 70px;
        margin-right: $gutter;
      }

      .downloadButtons {
        img {
          max-width: 80px;
          display: inline-block;
          margin: 4px 0;
        }
      }
    }
  }
}
