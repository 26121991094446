@import "../../structure.scss";

.publication.post {
  .intro {
    .introText {
      margin: 20px 0 0;

      @include mediumScreen {
        margin: 0 0 24px;
      }
    }
  }

  .executiveSummary {
    padding-bottom: 0;

    @include mediumScreen {
      padding-bottom: 24px;
    }

    .readingsList {
      li {
        margin-bottom: 0;
      }
    }
  }
}
