@import '../colors.scss';
@import '../structure.scss';
@import '../fonts.scss';

$smallGutter: 16px;
$gutter: 24px;
$largeGutter: 32px;

article {
  letter-spacing: -0.05px;

  h1 {
    @include smallScreen {
      text-align: center;
      font-weight: 600;
    }
  }

  a {
    text-decoration: underline;
    color: $linkBlue;
  }

  p {
    margin-bottom: 1rem;
  }

  .page {
    background-color: white;
    padding: 50px 40px 64px;
    border-bottom: 1px solid $darkDefaultGray;
    box-shadow: 0 2px 12px 0px rgba(200, 200, 200, 0.3);
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;
      box-shadow: 0;
      padding-top: 24px;
    }

    &:last-of-type {
      margin-bottom: 64px;
    }

    @include smallScreen {
      padding: 32px 20px 40px;

      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .twoCol {
    column-count: 2;

    @include smallScreen {
      column-count: 1;
    }
  }

  .pageFooter {
    display: flex;
    justify-content: center;
    padding-top: 16px;
    margin-top: 16px;
    border-top: 1px solid #a2a2a8;
    border-top: 1px solid #96a1a6;
  }

  .pageNumber {
    font-size: 0.95rem;
    color: $tealBlack;
  }

  p,
  li {
    text-align: justify;
  }

  img {
    width: 100%;
  }

  strong {
    font-weight: 600;
  }

  ul,
  ol {
    margin-left: 40px;

    @include smallScreen {
      margin-left: 24px;
    }
  }

  ul {
    li {
      list-style: disc;
    }
  }

  ol {
    li {
      list-style: decimal;
    }
  }

  li {
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  .lh15 {
    p {
      line-height: 1.5 !important;
    }
  }

  .blueBox {
    background: $tealLightest;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1.5rem 2rem;
    font-family: $secondaryFont;
    font-weight: 500;
    border-radius: 2px;
    break-inside: avoid-column;

    p {
      text-align: center;

      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    &.isParagraphText {
      font-family: $primaryFont;
      text-align: left;
      font-size: 15px;
      font-weight: 400;

      p {
        text-align: justify;
      }
    }
  }

  em {
    font-style: italic;
  }

  .tcct {
    .text {
      max-width: 350px;

      @include smallScreen {
        max-width: none;
      }
    }

    ul,
    ol {
      li {
        margin-bottom: 8px;

        &:last-of-type {
          margin-bottom: 12px;
        }
      }
    }

    p {
      margin-bottom: 12px;
    }

    .tc,
    .ct {
      display: flex;
      align-items: center;

      @include smallScreen {
        flex-direction: column;
      }
    }

    .tc {
      margin-bottom: $largeGutter;

      @include smallScreen {
        margin-bottom: $gutter;
      }

      .text {
        margin-right: $gutter;

        @include smallScreen {
          margin-right: 0;
        }
      }
    }

    .ct {
      margin-top: $largeGutter;

      @include smallScreen {
        margin-top: $gutter;
      }

      .text {
        margin-left: $gutter;
        order: 2;

        @include smallScreen {
          margin-left: 0;
          order: 1;
        }
      }

      img {
        @include smallScreen {
          order: 2;
        }
      }
    }

    img {
      &.full {
        width: 100%;
      }

      @include smallScreen {
        width: 100%;
      }
    }
  }
}
