$maxContentWidth: 1400;

$smallWidth: 768px; // tw = md
$mediumScreen: 980px; // tw = lg
$wideScreen: 1280px; // tw = xl

@mixin extraWideScreen {
  @media (min-width: $wideScreen) {
    @content;
  }
}

@mixin wideScreen {
  @media (max-width: $wideScreen) {
    @content;
  }
}

@mixin mediumScreen {
  @media (max-width: $mediumScreen) {
    @content;
  }
}

@mixin smallScreen {
  @media (max-width: $smallWidth) {
    @content;
  }
}

// Breakpoint prefix	Minimum width	CSS
// sm	640px	@media (min-width: 640px) { ... }
// md	768px	@media (min-width: 768px) { ... }
// lg	1024px	@media (min-width: 1024px) { ... }
// xl	1280px	@media (min-width: 1280px) { ... }
// 2xl	1536px
