@import '../../fonts.scss';
@import '../../colors.scss';
@import '../../structure.scss';

$smallGutter: 16px;
$gutter: 24px;
$largeGutter: 32px;

article.publication {
  .page {
    padding-bottom: 24px;
  }

  h1 {
    font-family: $secondaryFont;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 36px;
    line-height: 1.35;
    margin-bottom: 0.75rem;

    @include smallScreen {
      font-size: 24px;
    }
  }

  h2,
  h3 {
    text-transform: uppercase;
    font-weight: 600;
  }

  .intro {
    display: flex;
    margin-bottom: 24px;

    @include smallScreen {
      display: block;
    }

    .left {
      flex: 2;
    }

    .quotes {
      flex: 2;
      background-color: $lightDefaultGray;
      padding: 16px 20px;

      @include smallScreen {
        margin-bottom: $gutter;
        margin-left: -$gutter;
        margin-right: -$gutter;
      }
    }

    blockquote {
      margin-bottom: 12px;
      padding-bottom: 12px;
      border-bottom: 1px solid $darkDefaultGray;

      &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }

      p {
        font-family: $secondaryFont;
        margin-bottom: 0;
        letter-spacing: -0.25px;

        @include smallScreen {
          font-size: 15px;
        }
      }

      footer,
      footer p {
        text-align: right;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        font-family: $secondaryFont;
        margin-bottom: 0px;
      }

      footer {
        margin-top: 2px;
      }
    }

    .executiveSummary {
      flex: 1;
      background-color: $tealLightest;
      padding: $gutter;
      margin-left: 24px;

      @include smallScreen {
        margin-left: 0;
        margin-left: -$gutter;
        margin-right: -$gutter;
      }

      h2 {
        font-size: 15px;

        &:nth-of-type(2) {
          margin-top: 24px;
        }
      }

      ul {
        margin-left: 24px;
      }

      p,
      li {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .bottomChart {
    margin-top: $gutter;

    @include smallScreen {
      margin-top: 12px;
    }
  }
}
