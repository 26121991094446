@import './structure.scss';

.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  margin: 0 auto 20px;
  padding: 0;
  display: flex;
  position: relative;
  justify-content: center;
  overflow: hidden;

  @include mediumScreen {
    justify-content: flex-start;
    border-bottom: 1px solid #e0e3e5;
    overflow-x: auto;
    overflow-y: hidden;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -50vw;
    background-color: #e0e3e5;
    height: 1px;
    width: 200vw;
    z-index: 0;

    @include mediumScreen {
      content: none;
    }
  }
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 16px;
  position: relative;
  z-index: 1;

  // Portfolio Builder tabs
  .pb-tabs & {
    bottom: 0;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
}

.react-tabs__tab--selected {
  background: #fff;
  border-radius: 2px 2px 0 0;
  color: #254653;
  font-weight: 600;
  border-bottom: 3px solid #254653;

  // Portfolio Builder tabs
  .pb-tabs & {
    background: #eee;
    border-bottom: 1px solid #eee;
  }
}

.react-tabs__tab--disabled {
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: '';
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}
