@import './fonts.scss';
@import './structure.scss';
@import './colors.scss';
@import './tags';
@import './classes.scss';
@import './reactTabs.scss';
@import './reactDatepicker.scss';

.react-loading-skeleton {
  margin-bottom: 1rem;
  font-size: 20px;
}

:root {
  --gutter: 24px;
  --largeGutter: 64px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

@media screen and (max-width: $smallWidth) {
  :root {
    --gutter: 16px;
    --largeGutter: 32px;
  }
}

.retro-page {
  em {
    font-style: italic;
  }

  a {
    font-weight: 600;
    color: $linkBlue;
    text-decoration: underline;
  }
}

// For React-Tooltip transitions
.styles-module_tooltip__mnnfp {
  opacity: 0;
  transition: opacity 0.15s ease;
  will-change: opacity, visibility;
}

.styles-module_show__2NboJ {
  visibility: visible;
  opacity: 1;
}
